import { CountryCode, DAYSOFWEEK, NUMBERTYPE } from "./constant";
import i18n from "i18next";

export function ConvertUTCDateToLocalDate(utcTime) {
    var newDate = new Date(utcTime);
    return newDate.toLocaleString()
}

export function ConvertUTCDateToLocalSimpleDate(utcTime) {
    if (utcTime == null) return null
    var newDate = new Date(utcTime);
    const offset = newDate.getTimezoneOffset()
    newDate = new Date(newDate.getTime() - (offset * 60 * 1000))

    return newDate.toISOString().split('T')[0]
}

export function ConvertUTCDateToLocalMonth(utcTime) {
    if (utcTime == null) return null
    var newDate = new Date(utcTime);
    const offset = newDate.getTimezoneOffset()
    newDate = new Date(newDate.getTime() - (offset * 60 * 1000))

    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1

    return `${year}-${month}`; // Return YYYY-MM format
}

export function ConvertUTCDateToLocalDay(utcTime) {
    if (utcTime == null) return null
    var newDate = new Date(utcTime);
    const offset = newDate.getTimezoneOffset()
    newDate = new Date(newDate.getTime() - (offset * 60 * 1000))

    return newDate.getDate()
}

export function GetTime(timezone) {
    var d = new Date()

    switch (timezone) {
        case CountryCode.US:
            return d.toLocaleString('en-US', { timeZone: 'America/New_York', hour12: false })
        case CountryCode.SG:
            return d.toLocaleString('en-US', { timeZone: 'Asia/Singapore', hour12: false })
        default:
            return d.toLocaleString('en-US', { timeZone: 'Asia/Singapore', hour12: false })
    }
}

export function GetNumberStr(nr, type) {
    var number = null;
    if (nr === null) return nr
    try {
        number = Number(nr)
    } catch {
        return nr
    }

    switch (type) {
        case NUMBERTYPE.PCT:
            return (number * 100).toFixed(1) + '%'
        case NUMBERTYPE.B:
            return (number / 1000000000).toFixed(1).toLocaleString() + 'B'
        case NUMBERTYPE.M:
            return (number / 1000000).toFixed(1).toLocaleString() + 'M'
        case NUMBERTYPE.K:
            return (number / 1000).toFixed(1).toLocaleString() + 'K'
        case NUMBERTYPE.N1:
            return number.toFixed(1).toLocaleString()
        case NUMBERTYPE.N2:
            return number.toFixed(2).toLocaleString()
        default:
            return number
    }



}

export function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
}


export async function ClearLocalData() {
    localStorage.clear();
}

export function translate(text) {
    return i18n.t(text);
}

export function exportData(data, fileName, type) {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
};

export function isStringEmpty(input) {
    return typeof input === 'undefined' || input === null || input.length === 0;
}

export const getNearestDeliveryDate = (schedule) => {
    // console.log("schedule:", schedule)
    const weekDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
    const today = new Date();
    const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

    // console.log("currentDay", currentDay)

    // Map delivery days to their day numbers (0 to 6)
    const deliveryDays = schedule.split(",").sort((o1, o2) => weekDays.indexOf(o1) > weekDays.indexOf(o2) ? 1 : -1).map(day => DAYSOFWEEK.indexOf(day));

    // Find the nearest future date for delivery
    const futureDeliveryDays = deliveryDays.map(day => {
        let daysUntilNext = (day - currentDay + 7) % 7; // Calculate days until the next occurrence
        if (daysUntilNext === 0) daysUntilNext = 7; // If today is a delivery day, skip to the next week

        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + daysUntilNext);
        return nextDate;
    });

    // Return the nearest future date
    return futureDeliveryDays.sort((a, b) => a - b)[0];
};