// material-ui
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ZPromptConfirmation = ({ open, title, text, confirmButtonText, deleteButtonText, enableCancel, onClickConfirm, onClickDelete, onClickCancel, sx }) => {
    const { t } = useTranslation();
    return (
        <Dialog aria-describedby="simple-modal-description" open={open} fullWidth>
            <DialogTitle id="alert-dialog-title" sx={sx}>
                {title}
            </DialogTitle>
            <DialogContent sx={sx} >
                {/* <DialogContentText id="alert-dialog-description" color='black'> */}
                {text}
                {/* </DialogContentText> */}
            </DialogContent>
            <DialogActions sx={{ pr: 1, ...sx }}>
                {confirmButtonText &&
                    <Button variant="contained" color="primary" onClick={onClickConfirm}>{confirmButtonText}</Button>}
                {deleteButtonText &&
                    <Button variant="contained" color="error" onClick={onClickDelete}>{deleteButtonText}</Button>}
                {enableCancel &&
                    <Button variant="outlined" color="primary" onClick={onClickCancel}>{t('cancel')}</Button>}
            </DialogActions>
        </Dialog>
    )
}

export default ZPromptConfirmation;
