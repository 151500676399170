import { ROLE_MANAGER, ROLE_SYSADMIN, ROLE_TENANTADMIN, ROLE_USER } from 'aas/common/constant';
import { icons } from 'common/constant';
// import { ROLE_USER } from 'aas/common/constant';
// ==============================|| APP ||============================== //

//config ROLE_USER in parent level
export const appMenuItem = [
    {
        id: 'po-menu',
        title: 'purchase',
        type: 'collapse',
        collapses: true,
        package: 1,
        permission: ROLE_USER,
        icon: icons.IconShoppingCart,
        children: [
            {
                id: 'po-receive',
                title: 'receive',
                type: 'item',
                url: 'po/receive',
                permission: ROLE_USER,
                breadcrumbs: true
            },
            {
                id: 'po-view',
                title: 'purchase_order',
                type: 'item',
                url: 'po',
                permission: ROLE_MANAGER,
                breadcrumbs: true
            },
        ]
    },
    {
        id: 'stock-take',
        title: 'stock_take',
        type: 'item',
        collapses: false,
        package: 1,
        icon: icons.IconChecklist,
        url: 'inv/stocktake',
        permission: ROLE_MANAGER,
        breadcrumbs: true
    },
    {
        id: 'order-data',
        title: 'sales',
        type: 'collapse',
        collapses: false,
        icon: icons.IconCashRegister,
        permission: ROLE_USER,
        children: [
            {
                id: 'delivery-order',
                title: 'delivery_order',
                type: 'item',
                url: 'do',
                permission: ROLE_USER,
                breadcrumbs: true
            },
            {
                id: 'invoice',
                title: 'Invoice',
                type: 'item',
                url: 'invoice',
                permission: ROLE_USER,
                breadcrumbs: true
            },
        ]
    },

]


//child
export const appDashboardMenuItem = [
    // {
    //     id: 'dashboard-default',
    //     title: 'Dashboard',
    //     type: 'collapse',
    //     collapses: false,
    //     icon: icons.IconDashboard,
    //     children: [
    //         {
    //             id: 'dashboard-main',
    //             title: 'Overview',
    //             type: 'item',
    //             url: 'overview',
    //             breadcrumbs: true
    //         },
    //     ]
    // }
]

//child
export const appAdminMenuItem = [
    {
        id: 'reporting',
        title: 'reporting',
        type: 'collapse',
        package: 1,
        collapses: false,
        icon: icons.IconClipboardData,
        permission: ROLE_TENANTADMIN,
        children: [
            {
                id: 'monthly-closing-report',
                title: 'monthly_report',
                type: 'item',
                url: '/report/monthly',
                permission: ROLE_TENANTADMIN,
                breadcrumbs: true
            },
            {
                id: 'po-history',
                title: 'po_history',
                type: 'item',
                url: '/report/pohistory',
                permission: ROLE_TENANTADMIN,
                breadcrumbs: true
            },
        ]
    },
    {
        id: 'inventory-control',
        title: 'inventory_control',
        type: 'collapse',
        package: 1,
        collapses: false,
        icon: icons.IconBuildingWarehouse,
        permission: ROLE_TENANTADMIN,
        children: [
            {
                id: 'stock-take',
                title: 'stock_take',
                type: 'item',
                url: 'inv/stocktake/admin',
                permission: ROLE_TENANTADMIN,
                breadcrumbs: true
            },
            {
                id: 'product-inv',
                title: 'product_inventory',
                type: 'item',
                url: 'inv/product',
                permission: ROLE_TENANTADMIN,
                breadcrumbs: true
            },
            {
                id: 'inv-trans',
                title: 'inventory_transactions',
                type: 'item',
                url: 'inv/trans',
                permission: ROLE_TENANTADMIN,
                breadcrumbs: true
            }

        ]
    },

    {
        id: 'master-data',
        title: 'master_data',
        type: 'collapse',
        collapses: false,
        icon: icons.IconDatabaseCog,
        permission: ROLE_TENANTADMIN,
        children: [
            {
                id: 'client',
                title: 'Client',
                type: 'item',
                url: 'master/client',
                permission: ROLE_TENANTADMIN,
                breadcrumbs: true
            },
            {
                id: 'product',
                title: 'Product',
                type: 'item',
                url: 'master/product',
                permission: ROLE_TENANTADMIN,
                breadcrumbs: true
            },
            {
                id: 'supplier',
                title: 'supplier',
                type: 'item',
                url: 'master/supplier',
                permission: ROLE_TENANTADMIN,
                breadcrumbs: false,
                // icon: icons.IconHome,
            },

        ]
    },
    {
        id: 'configuration',
        title: 'Configuration',
        type: 'collapse',
        collapses: false,
        icon: icons.IconSettings,
        permission: ROLE_TENANTADMIN,
        children: [
            {
                id: 'category',
                title: 'Category',
                type: 'item',
                url: 'master/category',
                permission: ROLE_TENANTADMIN,
                breadcrumbs: true
            },
            {
                id: 'terms',
                title: 'Terms',
                type: 'item',
                url: 'master/terms',
                permission: ROLE_TENANTADMIN,
                breadcrumbs: true
            },
            {
                id: 'termtype',
                title: 'term_type',
                type: 'item',
                url: 'master/termtype',
                permission: ROLE_TENANTADMIN,
                breadcrumbs: true
            },
            {
                id: 'currency',
                title: 'Currency',
                type: 'item',
                url: 'master/currency',
                permission: ROLE_TENANTADMIN,
                breadcrumbs: true
            },
            {
                id: 'uom',
                title: 'Uom',
                type: 'item',
                url: 'master/uom',
                permission: ROLE_TENANTADMIN,
                breadcrumbs: true
            },
            {
                id: 'settings',
                title: 'Settings',
                type: 'item',
                url: 'settings',
                permission: ROLE_TENANTADMIN,
                breadcrumbs: false,
                // icon: icons.IconHome,
            },
        ]
    },
]


export const appSysAdminMenuItem =
{
    id: 'app-system-admin',
    title: 'tenant_maintenance',
    type: 'collapse',
    collapses: false,
    icon: icons.IconTool,
    permission: ROLE_SYSADMIN,
    children: [
        {
            id: 'invoice-do-management',
            title: 'invoice_do',
            type: 'item',
            url: 'maint/sysinvdo',
            permission: ROLE_SYSADMIN,
            breadcrumbs: false,
            // icon: icons.IconHome,
        },
        {
            id: 'invoice-po-management',
            title: 'purchase_order',
            type: 'item',
            url: 'maint/syspo',
            permission: ROLE_SYSADMIN,
            breadcrumbs: false,
            // icon: icons.IconHome,
        },
        {
            id: 'platform-notification-management',
            title: 'platform_notification',
            type: 'item',
            url: 'maint/notification',
            permission: ROLE_SYSADMIN,
            breadcrumbs: false,
            // icon: icons.IconHome,
        },
    ]
}

