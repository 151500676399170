import { ROLE_SYSADMIN, ROLE_TENANTADMIN } from 'aas/common/constant';
import { icons } from 'common/constant';
// ==============================|| System Admin Area ||============================== //

export const aasSysAdminMenuItems = [
    {
        id: 'tenant-management',
        title: 'tenant_management',
        type: 'collapse',
        collapses: false,
        icon: icons.IconHomeCog,
        permission: ROLE_SYSADMIN,
        children: [
            {
                id: 'tenant',
                title: 'Tenant',
                type: 'item',
                url: 'sys/tenant-management/tenants',
                permission: ROLE_SYSADMIN,
                breadcrumbs: false
            },
            {
                id: 'users',
                title: 'Users',
                type: 'item',
                permission: ROLE_SYSADMIN,
                url: 'sys/tenant-management/users',
                breadcrumbs: false
            },
        ]
    },
    {
        id: 'api-configuration',
        title: 'api_configuration',
        type: 'collapse',
        collapses: false,
        permission: ROLE_SYSADMIN,
        icon: icons.IconSettings,
        children: [
            {
                id: 'subscription-package',
                title: 'subscription_package',
                type: 'item',
                url: 'sys/config/packages',
                permission: ROLE_SYSADMIN,
                breadcrumbs: true
            },
            {
                id: 'servicegroup',
                title: 'servicegroup',
                type: 'item',
                url: 'sys/config/servicegroup',
                permission: ROLE_SYSADMIN,
                breadcrumbs: true
            },
            {
                id: 'services',
                title: 'Services',
                type: 'item',
                url: 'sys/config/services',
                permission: ROLE_SYSADMIN,
                breadcrumbs: true
            },
        ]
    },
    {
        id: 'scheduler-main',
        title: 'Scheduler',
        type: 'collapse',
        collapses: false,
        icon: icons.IconCalendarTime,
        permission: ROLE_SYSADMIN,
        children: [
            {
                id: 'scheduler',
                title: 'Scheduler',
                type: 'item',
                url: 'sys/scheduler',
                permission: ROLE_SYSADMIN,
                breadcrumbs: false
            },
            {
                id: 'scheduler-log',
                title: 'scheduler_log',
                type: 'item',
                url: 'sys/scheduler/log',
                permission: ROLE_SYSADMIN,
                breadcrumbs: false
            }
        ]
    }
]

//config ROLE_TENANTADMIN in parent level
export const adminMenuItems = {
    id: 'company-management',
    title: 'Company',
    type: 'collapse',
    collapses: false,
    icon: icons.IconBuilding,
    permission: ROLE_TENANTADMIN,
    children: [
        {
            id: 't-company-profile',
            title: 'company_profile',
            type: 'item',
            url: 'admin/company/profile',
            permission: ROLE_TENANTADMIN,
            breadcrumbs: false,
            // icon: icons.IconHome,

        },
        {
            id: 'department-management',
            title: 'department',
            type: 'item',
            url: 'admin/company/department',
            permission: ROLE_TENANTADMIN,
            breadcrumbs: false
        },
        {
            id: 't-user-management',
            title: 'company_users',
            type: 'item',
            url: 'admin/company/users',
            permission: ROLE_TENANTADMIN,
            breadcrumbs: false
        },
    ]
}