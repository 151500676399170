
import { useState } from 'react';
import { useTheme } from "@mui/material/styles";
import { useNavigate, Link } from "react-router-dom";
import validator from 'validator';
// material-ui
// import { styled } from '@mui/material/styles';
// import { useTheme } from "@mui/material/styles";
import {
  Divider, Grid, Stack, Typography, Button, TextField
} from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";
import SubCard from 'ui-component/cards/SubCard'

// project imports
import { gridSpacing } from 'common/constant';
import AnimateButton from 'ui-component/common/AnimateButton';

import AuthService from "aas/services/AuthService";
import BlueBgWrapper from "base/layout/BlueBgWrapper";
import { toast } from 'react-toastify';
import AppleSignin from 'react-apple-signin-auth';
import { Apple } from '@mui/icons-material';
import config from 'config';
import { isStringEmpty } from 'common/functions';
import ZBackdrop from 'ui-component/ZBackdrop';


// ================================|| AppleUpdateEmail ||================================ //

const AppleUpdateEmail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const authService = new AuthService();

  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [confirmEmailValid, setConfirmEmailValid] = useState(true);
  const [valid, setValid] = useState(false);



  const checkIfValid = (e1, e2) => {
    console.log(email.toLocaleLowerCase() !== confirmEmail.toLocaleLowerCase())
    if (isStringEmpty(e1) || isStringEmpty(e2) || !validator.isEmail(e1) || !validator.isEmail(e2) || (e1.toLocaleLowerCase() !== e2.toLocaleLowerCase())) {
      setValid(false)
    } else {
      setValid(true)
    }
  }

  const handleAppleChangeEmail = async (loginResponse) => {
    console.log('pressed')

    if (isStringEmpty(email) || isStringEmpty(confirmEmail) || !validator.isEmail(email) || !validator.isEmail(confirmEmail) || (email.toLocaleLowerCase() !== confirmEmail.toLocaleLowerCase())) {
      toast.error('Email Invalid');
      setValid(false)
      return
    }
    setLoading(true)
    authService.appleChangeEmail(loginResponse, email).then(
      async (response) => {
        if (response.status === 200 && response.data.code === "SUCCESS") {
          toast.success('Request Send, our customer service will contact you shortly via email.')
        } else {
          toast.error(response?.data?.code, response?.data?.message);
        }
        setLoading(false)
        navigate('/login');
      }, error => {
        toast.error(error.message);
        setLoading(false)
      })
  }

  return (
    <BlueBgWrapper>
      <ZBackdrop open={isLoading} />
      <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: "100vh" }}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: "calc(100vh - 68px)" }}>
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <MainCard
                sx={{
                  width: { xs: 370, lg: 475 },
                  margin: { xs: 2.5, md: 3 },
                  "& > *": { flexGrow: 1, flexBasis: "50%", },
                }} content={false}>

                <SubCard sx={{ border: 0 }} >
                  <Grid item xs={12} sx={{ my: 2 }} container alignItems="center" justifyContent="center">
                    <Typography variant="subtitle1">Update Apple Registration Email</Typography>
                  </Grid>

                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Email Address"
                        value={email ?? ""}
                        error={!emailValid}
                        onChange={(e) => {
                          setEmail(e.target.value)
                          setEmailValid(validator.isEmail(e.target.value))
                          setConfirmEmailValid(confirmEmail.toLocaleLowerCase() === e.target.value.toLocaleLowerCase())
                          checkIfValid(e.target.value, confirmEmail)
                        }}
                        helperText={!emailValid ? 'Invalid Email Format.' : ''}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Confirm Email Address"
                        value={confirmEmail ?? ""}
                        error={!confirmEmailValid}
                        onChange={(e) => {
                          setConfirmEmail(e.target.value)
                          setConfirmEmailValid(email.toLocaleLowerCase() === e.target.value.toLocaleLowerCase())
                          checkIfValid(email, e.target.value)
                        }}
                        helperText={!confirmEmailValid ? 'Mismatch Email.' : ''}
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ mb: 1 }}>
                      <AppleSignin
                        /** Auth options passed to AppleID.auth.init() */
                        authOptions={{
                          /** Client ID - eg: 'com.example.com' */
                          clientId: config.APPLE_CLIENT_ID,
                          /** Requested scopes, seperated by spaces - eg: 'email name' */
                          scope: 'email name',
                          /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                          redirectURI: window.location.origin + '/login',
                          // redirectURI: 'https://test.expressoom.com/api/apple/callback',
                          /** State string that is returned with the apple response */
                          state: 'state',
                          /** Nonce */
                          nonce: 'nonce',
                          /** Uses popup auth instead of redirection */
                          usePopup: true,
                        }} // REQUIRED
                        /** Extra controlling props */
                        /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
                        onSuccess={(response) => handleAppleChangeEmail(response)} // default = undefined
                        /** Called upon signin error */
                        onError={(error) => console.error(error)} // default = undefined
                        /** Skips loading the apple script if true */
                        skipScript={false} // default = undefined

                        /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
                        render={(props) => <AnimateButton><Button disableElevation fullWidth size="large" variant="outlined" onClick={props.onClick}
                          sx={{ color: 'grey.700', backgroundColor: theme.palette.grey[50], borderColor: theme.palette.primary }}
                          disabled={!valid}
                          startIcon={<Apple />}>
                          {/* <img src={Apple} alt="google" width={16} height={16} style={{ marginRight: 10 }} /> */}
                          Send Request
                        </Button></AnimateButton>}
                      />
                    </Grid>
                  </Grid>
                </SubCard>

                <SubCard sx={{ border: 0 }} >
                  <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item container direction="column" alignItems="center" xs={12}>
                        <Typography component={Link} to="/login" variant="subtitle1" sx={{ textDecoration: "none" }}>
                          Back to Login
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </SubCard>
              </MainCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2" target="_blank" underline="hover">
              expressoom.com
            </Typography>
            <Typography variant="subtitle2" target="_blank" underline="hover">
              &copy; expressoom.com
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </BlueBgWrapper >
  );
};

export default AppleUpdateEmail;
